.single-feature2 {
  border-radius: 5px;
  transition: 0.5s;

  img {
    margin-bottom: 40px;
  }
}

.universal0 {
  margin-top: 150px;

  .container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: auto;

    .item {
      width: 340px;
      margin: 10px;
      display: flex;

    flex-direction: column;
      box-shadow: 0 0 16px 3px #0000001f;
      @media only screen and (max-width: 1192px) {
        width: 396px;
        margin: 24px;
      }
      @media only screen and (max-width: 1024px) {
        width: 100%;
      }
      @media only screen and (max-width: 411px) {
        width: 100%;
        margin: 10px 0;
      }

      .category__item-done {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .category__image-done{
          img{
              width: 100%;
            @media only screen and (max-width: 1024px) {
              width: 100%;
            }
            @media only screen and (max-width: 411px) {
              width: 100%;
            }
          }
        }

        .information-wrapper {
          padding: 20px;

          .category__title {
            max-height: 75px;

            span {
              font-size: 19px;
            }
          }

          .price-wrapper {
            font-size: 25px;
            color: #0076ff;
            text-align: left;
          }
        }
      }
    }
  }
}

.single-feature3 {
  transition: 0.5s;
  position: relative;

  img {
    max-width: 285px;
    max-height: inherit;
  }
  // h3 {
  //   position: absolute;
  //   padding: 40px;
  //   background: linear-gradient(90deg,#244cfd,#15e4fd);
  //   border-radius: 20px;
  //   top: 25%;
  //   left: 25%;
  //   color: white;
  //   box-shadow: 2px 2px 16px 3px #000000e0;
  // }
}

.shop-box {
  .container {
    .row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-self: stretch;

      .item {
        width: 25%;
        height: 285px;
        background: #f9f9fd;
        transition: 0.3s;

        h3 {
          text-align: center;
        }
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
        @media only screen and (max-width: 411px) {
          width: 100%;
        }

        &:hover {
          background: #0086eabf;
          transition: 0.3s;

          h3 {
            // color: #fff;
          }
        }
      }
    }
  }
}
