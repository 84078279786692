/* 16. Form */
.appointment-form {
    input,
    textarea {
        width: 100%;
        padding: 12px 20px;
        border: none;
    }
    .template-btn {
        width: 100%;
    }
}