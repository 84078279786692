// Font 
$heading-font: 'Ubuntu', sans-serif;
$body-font: 'Roboto', sans-serif;

// Color 
$primary-color: #244cfd;
$heading-text: #222222;
$body-text: #777777;
$light: #ffffff;
$dark: #f9f9fd;
$border: #f9f9f9;
$shadow: rgba(0,0,0,.1);
$footer: #12151f;
$placeholder: #b0b0b0;
$overlay: rgba(1,2,14,.4);
$gradient: linear-gradient(to right, #244cfd, #15e4fd);
$blog-category: rgba(36, 76, 253, .7);
