.left-category {
  position: fixed;
  background: #0086ea;
  overflow-x: auto;
  overflow-y: scroll;
  left: 0;
  width: 16%;
  padding: 30px;
  top: 0;
  z-index: 99999;
  bottom: 0;
  box-shadow: 7px 0 20px 0 #59687163;
  @media only screen and (max-width: 1600px) {
    display: none;
  }
  @media only screen and (max-width: 411px) {}

  ul {
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 15px;
      transition: all 0.1s;
      border: 3px solid #0086ea00;

      &:hover {
        border: 3px solid #fff;
        cursor: pointer;
        transition: all 0.2s;
      }

      a {
        color: #fff;
        line-height: 30px;
        text-align: center;
        margin: 5px;
        width: 100%;
      }
    }

    .active {
      border: 3px solid #fff;
    }
  }
}

.card-gallery__bottom .slick-slide {
  padding: 0 8.5px;
}

.card-gallery__bottom .slick-slide a,
.card-gallery__bottom .slick-slide img {
  display: block !important;
}

.card-gallery__bottom .slick-slide a {
  cursor: pointer;
}

.card-gallery__bottom .slick-slide img {
  max-width: 100%;
  border-bottom: 6px solid transparent;
  outline-offset: -6;
}

.card-gallery__bottom .slick-slide img.active {
  border-bottom: #c6d250 solid 6px;
  outline-offset: -6px;
}

.slick-slider {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list,
.slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0s;
  transition: all 0s;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:after,
.slick-track:before {
  content: '';
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-arrow {
  padding: 0;
}

.slick-arrow:focus {
  outline: 0;
}

.slick__arrow.slick-hidden {
  display: none;
}

.slick-slide {
  float: left;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide:focus {
  outline: 0;
  cursor: move;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-slide > img {
  display: block;
}

.slick-slide.dragging > img {
  pointer-events: none;
}

.slick-dots button:focus {
  outline: 0;
}

#msProduct {
  display: flex;
  // align-items: flex-start!important;
}

.mrgntop {
  margin-top: 140px;

}
.mrgntopsmall {
  margin-top: 30px;

}

.card {
  margin: 10px!important;
  width: 215px!important;
  @media only screen and (max-width: 1600px) {

  }

  @media only screen and (max-width: 520px) {
    width: 100%!important;
  }
}

.btn{
  white-space: pre-wrap!important;
}
.card-body{
  justify-content: center!important;
display: flex!important;
flex-direction: column!important;
padding: 30px!important;
}
.form-desc__form{
  color: #fff;
  button{
     border: none!important;
  }
  .form-item{

    label{
      color:#fff;
          font-size: 16px;
    }
  }
}
.contact-form {
  .container{
    .col-lg-9{
      .form-desc__form{
        color: black!important;
        #text{
          color: black!important;
        }
      }
    }
  }
}
