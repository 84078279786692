/* 8. Department */
.department-area {
    background: $dark;
    .single-slide {
        margin: 0 5% 20px;
        &:hover {
            .item-padding {
                box-shadow: 0 5px 20px $shadow;
            }
            .slide-img {
                .hover-state {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        .item-padding {
            background: $light;
            padding: 40px;
            transition: .5s;
            @include portrait-phone() {
                padding: 40px 20px;
            }
        }
        .slide-img {
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            .hover-state {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: $overlay;
                transition: .5s;
                opacity: 0;
                visibility: hidden;
                i {
                    color: $light;
                    font-size: 50px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
    }
}

.department-slider.owl-carousel .owl-nav {
    display: none;
}
.department-slider.owl-carousel .owl-dots {
    display: block;
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translate(-50%,0);
    .owl-dot {
        width: 15px;
        height: 4px;
        background: #c6d0d4;
        border-radius: 5px;
        margin: 0 7px;
    }
    .owl-dot.active {
        background: $primary-color;
    }
}
