/* 6. Feature */
.single-feature {

    border-radius: 5px;
    transition: .5s;
    img {
        margin-bottom: 40px;
        width: 40%;
    }
    &:hover {
        background: $light;
        box-shadow: 0 5px 20px $shadow;
    }
}
