/* 9. Patient */
.patient-area {
    .single-patient  {
        background: $dark;
        padding: 40px 80px 20px 40px;
        transition: .5s;
        margin-top: 90px;
        &:hover {
            background: $light;
            box-shadow: 0 5px 20px $shadow;
        }
        img {
            margin-bottom: 24px;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-top: -90px;
        }
    }
    .appointment-form {
        padding: 80px;
        
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        z-index: 1;
        @include desktop(){
            padding: 80px 40px;
        }
        @include portrait-phone(){
            padding: 40px 20px;
        }
        &:before {
            content: '';
            position: absolute;
            top: -5%;
            right: -30%;
            width: 60%;
            height: 110%;
            border: 10px solid $border;
            z-index: -1;
            @include desktop(){
                right: -20%;
            }
            @include tablet(){
                position: inherit;
            }
            @include landscape-phone(){
                position: inherit;
            }
            @include portrait-phone(){
                position: inherit;
            }
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to top right, rgba(21, 228, 253,.9), rgba(36, 76, 253,.9));
            z-index: -1;
        }
        h3 {
            font-size: 24px!important;
            color: $light!important;
        }
    }
}
