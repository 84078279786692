.catalog-box {
  .container {


    .row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-self: stretch;
      background: #f9f9fd;

      .item{
        width: 23%;
        height: 285px;
        margin: 10px;
        
        transition: .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;



        @media only screen and (max-width: 1024px){
          width: 100%;
          height: 414px;
        }

        @media only screen and (max-width: 411px){
          width: 100%;
        }
        &:hover{
          background: linear-gradient(to top right,rgba(21,228,253,.9),rgba(36,76,253,.9));
          transition: all 0.3s ;
          transform: scale(1.12);
          h3{
            color: #fff;
          }
        }
      }
    }

  }

}
