@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,500,700");
/* 1. Author CSS */
body {
  color: #777777;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400; }

h1 {
  color: #222222;
  font-family: "Ubuntu", sans-serif;
  font-size: 45px !important;
  font-weight: 700; }

h2 {
  color: #222222;
  font-family: "Ubuntu", sans-serif;
  font-size: 36px !important;
  font-weight: 700; }

h3 {
  color: #222222;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px !important;
  font-weight: 700;
  text-transform: uppercase; }

h4 {
  color: #222222;
  font-family: "Ubuntu", sans-serif;
  font-size: 16px !important;
  font-weight: 400; }

h5 {
  color: #777777;
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: 14px !important;
  font-weight: 400; }

h6 {
  color: #777777;
  font-size: 12px !important;
  font-family: "Roboto", sans-serif;
  font-weight: 500; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

a:focus,
a:hover {
  text-decoration: none; }

input:focus,
textarea:focus {
  outline: none; }

.custom-a {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center; }
  .custom-a h3 {
    word-wrap: break-word; }

.category-ul {
  justify-content: center;
  display: flex;
  flex-direction: column; }
  .category-ul ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%; }
    .category-ul ul li {
      display: flex;
      width: 28%;
      margin: 27px; }
      @media only screen and (max-width: 1024px) {
        .category-ul ul li {
          width: 95%; } }
      .category-ul ul li a {
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        justify-content: center;
        padding: 20px;
        height: 280px;
        outline: 17px solid;
        transition: all .3s ease 0s;
        outline-color: #f0f8ff;
        font-size: 22px;
        color: #000000; }
        .category-ul ul li a:hover {
          transition: all .3s ease 0s;
          background: linear-gradient(to top right, rgba(21, 228, 253, 0.9), rgba(36, 76, 253, 0.9));
          color: #fff;
          outline: 0px solid;
          outline-color: #fff;
          transform: scale(1.12); }

/* 2. Preloader */
.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #244cfd;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 5; }
  .preloader .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%); }

/* 3. Main Menu */
.nav-menu, .nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none; }

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99; }

.nav-menu li {
  position: relative;
  white-space: nowrap; }

.nav-menu > li {
  float: left; }

.nav-menu li:hover > ul,
.nav-menu li.sfHover > ul {
  display: block; }

.nav-menu ul ul {
  top: 0;
  left: 100%; }

.nav-menu ul li {
  min-width: 180px;
  text-align: left; }

.menu-has-children ul li a {
  padding: 4px 10px !important;
  text-transform: uppercase !important; }

.sf-arrows .sf-with-ul {
  padding-right: 30px; }

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 15px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal; }

.sf-arrows ul .sf-with-ul:after {
  content: "\f105"; }

#nav-menu-container {
  margin: 0; }

@media (max-width: 960px) {
  #nav-menu-container {
    display: none; } }

.nav-menu a {
  padding: 7px 10px;
  text-decoration: none;
  display: inline-block;
  color: #222222;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  outline: none; }

.nav-menu > li {
  margin-left: 15px; }

.nav-menu ul {
  margin: 14px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff; }

.nav-menu ul li {
  transition: 0.3s; }

.nav-menu ul li a {
  padding: 10px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 12px;
  text-transform: none; }

.nav-menu ul li:hover > a {
  color: #244cfd; }

.nav-menu ul ul {
  margin: 0; }

#mobile-nav-toggle {
  position: fixed;
  right: 15px;
  z-index: 999;
  top: 20px;
  border: 0;
  background: none;
  font-size: 24px;
  display: none;
  transition: all 0.4s;
  outline: none;
  cursor: pointer; }

#mobile-nav-toggle i {
  color: #244cfd;
  font-weight: 900; }

@media (max-width: 960px) {
  #mobile-nav-toggle {
    display: inline; }
  #nav-menu-container {
    display: none; } }

#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(0, 0, 0, 0.8);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s; }

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none; }

#mobile-nav ul li {
  position: relative; }

#mobile-nav ul li a {
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
  font-weight: 700; }

#mobile-nav ul li a:hover {
  color: #fff; }

#mobile-nav ul li li {
  padding-left: 30px; }

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff; }

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #244cfd; }

#mobile-nav ul .menu-has-children li a {
  text-transform: capitalize; }

#mobile-nav ul .menu-item-active {
  color: #244cfd; }

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: none; }

body.mobile-nav-active {
  overflow: hidden; }

body.mobile-nav-active #mobile-nav {
  left: 0; }

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff; }

/* 4. Header */
.header-top {
  padding: 10px 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1; }
  .header-top h6 {
    margin-top: 6px; }
    .header-top h6 i {
      font-size: 18px;
      position: relative;
      top: 2px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .header-top {
      display: none; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .header-top {
      display: none; } }
  @media (max-width: 575.98px) {
    .header-top {
      display: none; } }
  .header-top .social-links ul {
    float: right;
    margin-top: 5px; }
    .header-top .social-links ul li {
      display: inline;
      margin-left: 15px; }
      .header-top .social-links ul li a {
        color: #777777;
        transition: all .3s ease-in-out; }
        .header-top .social-links ul li a:hover {
          color: #244cfd; }

#header {
  background-color: #ffffff;
  padding: 10px;
  position: fixed;
  left: 0;
  top: 0px;
  right: 0;
  transition: all 0.5s;
  z-index: 997; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    #header {
      top: 0; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    #header {
      top: 0; } }
  @media (max-width: 575.98px) {
    #header {
      top: 0; } }
  #header #home {
    padding: 10px; }

#header.header-scrolled {
  background: #ffffffeb;
  transition: all 0.5s;
  top: 0; }

@media (max-width: 673px) {
  #logo {
    margin-left: 20px; } }

#header #logo h1 {
  font-size: 34px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 3px; }

#header #logo h1 a, #header #logo h1 a:hover {
  color: #fff;
  padding-left: 10px;
  border-left: 4px solid #244cfd; }

#header #logo img {
  padding: 0;
  margin: 0; }

@media (max-width: 768px) {
  #header #logo h1 {
    font-size: 28px; }
  #header #logo img {
    max-height: 40px; } }

/* 5. Banner */
.banner-area {
  padding: 400px 0 350px;
  background-color: #b0b0b0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .banner-area {
      padding: 280px 0; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .banner-area {
      padding: 250px 0; } }
  @media (max-width: 575.98px) {
    .banner-area {
      padding: 200px 0; } }

/* 6. Feature */
.single-feature {
  border-radius: 5px;
  transition: .5s; }
  .single-feature img {
    margin-bottom: 40px;
    width: 40%; }
  .single-feature:hover {
    background: #ffffff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1); }

/* 7. Welcome */
.welcome-img img {
  width: 120%;
  position: relative;
  z-index: 1; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .welcome-img img {
      width: 100%; } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .welcome-img img {
      width: 100%; } }
  @media (max-width: 575.98px) {
    .welcome-img img {
      width: 100%; } }

.welcome-text {
  padding: 100px;
  border: 10px solid #f9f9f9; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .welcome-text {
      padding: 50px; } }
  @media (max-width: 575.98px) {
    .welcome-text {
      padding: 40px 20px; } }

/* 8. Department */
.department-area {
  background: #f9f9fd; }
  .department-area .single-slide {
    margin: 0 5% 20px; }
    .department-area .single-slide:hover .item-padding {
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1); }
    .department-area .single-slide:hover .slide-img .hover-state {
      opacity: 1;
      visibility: visible; }
    .department-area .single-slide .item-padding {
      background: #ffffff;
      padding: 40px;
      transition: .5s; }
      @media (max-width: 575.98px) {
        .department-area .single-slide .item-padding {
          padding: 40px 20px; } }
    .department-area .single-slide .slide-img {
      position: relative;
      border-radius: 5px;
      overflow: hidden; }
      .department-area .single-slide .slide-img .hover-state {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(1, 2, 14, 0.4);
        transition: .5s;
        opacity: 0;
        visibility: hidden; }
        .department-area .single-slide .slide-img .hover-state i {
          color: #ffffff;
          font-size: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }

.department-slider.owl-carousel .owl-nav {
  display: none; }

.department-slider.owl-carousel .owl-dots {
  display: block;
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translate(-50%, 0); }
  .department-slider.owl-carousel .owl-dots .owl-dot {
    width: 15px;
    height: 4px;
    background: #c6d0d4;
    border-radius: 5px;
    margin: 0 7px; }
  .department-slider.owl-carousel .owl-dots .owl-dot.active {
    background: #244cfd; }

/* 9. Patient */
.patient-area .single-patient {
  background: #f9f9fd;
  padding: 40px 80px 20px 40px;
  transition: .5s;
  margin-top: 90px; }
  .patient-area .single-patient:hover {
    background: #ffffff;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1); }
  .patient-area .single-patient img {
    margin-bottom: 24px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: -90px; }

.patient-area .appointment-form {
  padding: 80px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1; }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .patient-area .appointment-form {
      padding: 80px 40px; } }
  @media (max-width: 575.98px) {
    .patient-area .appointment-form {
      padding: 40px 20px; } }
  .patient-area .appointment-form:before {
    content: '';
    position: absolute;
    top: -5%;
    right: -30%;
    width: 60%;
    height: 110%;
    border: 10px solid #f9f9f9;
    z-index: -1; }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .patient-area .appointment-form:before {
        right: -20%; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .patient-area .appointment-form:before {
        position: inherit; } }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .patient-area .appointment-form:before {
        position: inherit; } }
    @media (max-width: 575.98px) {
      .patient-area .appointment-form:before {
        position: inherit; } }
  .patient-area .appointment-form:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top right, rgba(21, 228, 253, 0.9), rgba(36, 76, 253, 0.9));
    z-index: -1; }
  .patient-area .appointment-form h3 {
    font-size: 24px !important;
    color: #ffffff !important; }

/* 10. Specialist */
.specialist-area {
  background: #f9f9fd; }
  .specialist-area .single-doctor:hover .content-area {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1); }
  .specialist-area .single-doctor:hover .doctor-name {
    background: linear-gradient(to right, #244cfd, #15e4fd); }
    .specialist-area .single-doctor:hover .doctor-name h3,
    .specialist-area .single-doctor:hover .doctor-name h6 {
      color: #ffffff; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .specialist-area img {
      width: 100%; } }
  @media (max-width: 575.98px) {
    .specialist-area img {
      width: 100%; } }
  .specialist-area .content-area {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: .5s; }
  .specialist-area .doctor-name {
    padding: 20px;
    background: #f9f9fd;
    transition: .5s; }
  .specialist-area .doctor-text {
    background: #ffffff;
    padding: 20px; }
  .specialist-area .doctor-icon li {
    display: inline;
    margin: 0 7px; }
    .specialist-area .doctor-icon li a {
      color: #777777;
      transition: .3s; }
      .specialist-area .doctor-icon li a:hover {
        color: #244cfd; }

/* 11. Hotline */
.hotline-area {
  color: #ffffff;
  background-image: url("../images/hotline-bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1; }
  .hotline-area:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top right, rgba(36, 76, 253, 0.9), rgba(21, 228, 253, 0.9));
    z-index: -1; }
  .hotline-area h2 {
    color: #ffffff; }
  .hotline-area span {
    font-size: 56px;
    font-family: "Roboto", sans-serif;
    font-weight: 900; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .hotline-area span {
        font-size: 45px; } }
    @media (max-width: 575.98px) {
      .hotline-area span {
        font-size: 45px; } }

/* 12. News */
.news-area .news-date {
  color: #ffffff;
  background: #244cfd;
  padding: 12px 30px;
  display: inline-block;
  position: relative;
  top: -25px;
  left: 30px; }

.news-area h3 a {
  color: #222222;
  transition: .3s; }

@media (min-width: 576px) and (max-width: 767.98px) {
  .news-area img {
    width: 100%; } }

.news-area .single-news:hover h3 a {
  color: #244cfd; }

/* 13. Footer */
.footer-area {
  color: #ffffff;
  background: #12151f;
  margin-top: 30px; }
  .footer-area h3 {
    color: #ffffff; }
  .footer-area a {
    color: #ffffff;
    text-transform: capitalize;
    transition: .3s; }
    .footer-area a:hover {
      color: #244cfd; }
  .footer-area input {
    display: inline-block;
    margin: auto;
    width: 250px;
    border: none;
    border-radius: 3px;
    padding: 10px 20px; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .footer-area input {
        width: 250px; } }
    @media (max-width: 575.98px) {
      .footer-area input {
        width: 100%; } }
  .footer-area .template-btn {
    margin-left: 10px;
    border: none;
    width: 170px; }
    @media (max-width: 575.98px) {
      .footer-area .template-btn {
        margin-left: 0;
        margin-top: 30px; } }
  .footer-area img {
    width: 55px;
    height: 55px;
    margin-right: 5px;
    margin-bottom: 10px; }

.footer-copyright {
  margin-top: 70px; }
  .footer-copyright .color {
    color: #244cfd; }
  .footer-copyright ul {
    float: right; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .footer-copyright ul {
        float: left;
        margin-top: 30px; } }
    @media (max-width: 575.98px) {
      .footer-copyright ul {
        float: left;
        margin-top: 30px; } }
    .footer-copyright ul li {
      display: inline;
      margin-left: 10px; }
      .footer-copyright ul li i {
        display: inline-block;
        padding: 10px;
        color: #777777;
        background: #ffffff;
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 20px;
        transition: all .3s ease-in-out; }
        .footer-copyright ul li i:hover {
          color: #ffffff;
          background: linear-gradient(to right, #244cfd, #15e4fd); }

/* 14. Departments Page */
.banner-area.other-page {
  padding: 220px 0 170px; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .banner-area.other-page {
      padding: 250px 0 100px; } }
  @media (max-width: 575.98px) {
    .banner-area.other-page {
      padding: 250px 0 100px; } }
  .banner-area.other-page a {
    color: #777777;
    transition: .3s; }
    .banner-area.other-page a:hover {
      color: #244cfd; }

/* 15. About */
/* 16. Blog Home */
.blog_categorie_area {
  padding-top: 80px;
  padding-bottom: 80px; }

.categories_post {
  position: relative;
  text-align: center;
  cursor: pointer; }
  .categories_post img {
    max-width: 100%; }
    @media (min-width: 576px) and (max-width: 767.98px) {
      .categories_post img {
        width: 100%; } }
  .categories_post .categories_details {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(1, 2, 14, 0.4);
    color: #fff;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center; }
    .categories_post .categories_details h5 {
      margin-bottom: 0px;
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      color: #fff;
      position: relative; }
    .categories_post .categories_details p {
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      margin-bottom: 0px; }
    .categories_post .categories_details .border_line {
      margin: 10px 0px;
      background: #fff;
      width: 100%;
      height: 1px; }
  .categories_post:hover .categories_details {
    background: rgba(36, 76, 253, 0.7); }

/*============ blog_left_sidebar css ==============*/
.blog_item {
  margin-bottom: 40px; }

.blog_info {
  padding-top: 30px; }
  .blog_info .post_tag {
    padding-bottom: 20px; }
    .blog_info .post_tag a {
      font: 300 14px/21px;
      color: #222222; }
      .blog_info .post_tag a:hover {
        color: #244cfd; }
      .blog_info .post_tag a.active {
        color: #244cfd; }
  .blog_info .blog_meta li a {
    font: 300 14px/20px;
    color: #777777;
    vertical-align: middle;
    padding-bottom: 12px;
    display: inline-block; }
    .blog_info .blog_meta li a i {
      color: #222222;
      font-size: 16px;
      font-weight: 600;
      padding-left: 15px;
      line-height: 20px;
      vertical-align: middle; }
    .blog_info .blog_meta li a:hover {
      color: #244cfd; }

.blog_post img {
  max-width: 100%; }

.blog_details {
  padding-top: 20px; }
  .blog_details h4 {
    font-size: 24px;
    line-height: 36px;
    color: #222222;
    font-weight: 600;
    transition: all 0.3s linear; }
    .blog_details h4:hover {
      color: #244cfd; }
  .blog_details p {
    margin-bottom: 26px; }
  .blog_details .template-btn {
    color: #222222 !important;
    padding: 10px 24px !important;
    background: #f9f9fd; }
    .blog_details .template-btn:hover {
      color: #ffffff !important;
      background: linear-gradient(to right, #244cfd, #15e4fd); }

.blog_right_sidebar {
  border: 1px solid #f9f9f9;
  background: #f9f9fd;
  padding: 30px; }
  .blog_right_sidebar .widget_title {
    font-size: 18px;
    line-height: 25px;
    background: linear-gradient(to right, #244cfd, #15e4fd);
    text-align: center;
    color: #fff;
    padding: 8px 0px;
    margin-bottom: 30px; }
  .blog_right_sidebar .search_widget .input-group .form-control {
    font-size: 14px;
    line-height: 29px;
    border: 0px;
    width: 100%;
    font-weight: 300;
    color: #ffffff;
    padding-left: 20px;
    border-radius: 45px;
    z-index: 0;
    background: linear-gradient(to right, #244cfd, #15e4fd); }
    .blog_right_sidebar .search_widget .input-group .form-control:focus {
      box-shadow: none; }
  .blog_right_sidebar .search_widget .input-group .btn-default {
    position: absolute;
    right: 20px;
    background: transparent;
    border: 0px;
    box-shadow: none;
    font-size: 14px;
    color: #ffffff;
    padding: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1; }
  .blog_right_sidebar .author_widget {
    text-align: center; }
    .blog_right_sidebar .author_widget h5 {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 5px;
      margin-top: 30px; }
    .blog_right_sidebar .author_widget p {
      margin-bottom: 0px; }
    .blog_right_sidebar .author_widget .social_icon {
      padding: 7px 0px 15px; }
      .blog_right_sidebar .author_widget .social_icon a {
        font-size: 14px;
        color: #222222;
        transition: all 0.2s linear; }
        .blog_right_sidebar .author_widget .social_icon a + a {
          margin-left: 20px; }
        .blog_right_sidebar .author_widget .social_icon a:hover {
          color: #244cfd; }
  .blog_right_sidebar .popular_post_widget .post_item .media-body {
    justify-content: center;
    align-self: center;
    padding-left: 20px; }
    .blog_right_sidebar .popular_post_widget .post_item .media-body h5 {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      transition: all 0.3s linear; }
      .blog_right_sidebar .popular_post_widget .post_item .media-body h5:hover {
        color: #244cfd; }
    .blog_right_sidebar .popular_post_widget .post_item .media-body p {
      font-size: 12px;
      line-height: 21px;
      margin-bottom: 0px; }
  .blog_right_sidebar .popular_post_widget .post_item + .post_item {
    margin-top: 20px; }
  .blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 2px dotted #eee;
    transition: all 0.3s ease 0s;
    padding-bottom: 12px; }
    .blog_right_sidebar .post_category_widget .cat-list li a {
      font-size: 14px;
      line-height: 20px;
      color: #777; }
      .blog_right_sidebar .post_category_widget .cat-list li a p {
        margin-bottom: 0px; }
    .blog_right_sidebar .post_category_widget .cat-list li + li {
      padding-top: 15px; }
    .blog_right_sidebar .post_category_widget .cat-list li:hover {
      border-color: #244cfd; }
      .blog_right_sidebar .post_category_widget .cat-list li:hover a {
        color: #244cfd; }
  .blog_right_sidebar .newsletter_widget {
    text-align: center; }
    .blog_right_sidebar .newsletter_widget .form-group {
      margin-bottom: 8px; }
    .blog_right_sidebar .newsletter_widget .input-group-prepend {
      margin-right: -1px; }
    .blog_right_sidebar .newsletter_widget .input-group-text {
      background: #fff;
      border-radius: 0px;
      vertical-align: top;
      font-size: 12px;
      line-height: 36px;
      padding: 0px 0px 0px 15px;
      border: 1px solid #eeeeee;
      border-right: 0px; }
    .blog_right_sidebar .newsletter_widget .form-control {
      font-size: 12px;
      line-height: 24px;
      color: #cccccc;
      border: 1px solid #eeeeee;
      border-left: 0px;
      border-radius: 0px; }
      .blog_right_sidebar .newsletter_widget .form-control:focus {
        outline: none;
        box-shadow: none; }
    .blog_right_sidebar .newsletter_widget .bbtns {
      color: #fff;
      background: linear-gradient(to right, #244cfd, #15e4fd);
      font-size: 12px;
      line-height: 38px;
      display: inline-block;
      font-weight: 500;
      padding: 0px 24px 0px 24px;
      border-radius: 0; }
    .blog_right_sidebar .newsletter_widget .text-bottom {
      font-size: 12px; }
  .blog_right_sidebar .tag_cloud_widget ul li {
    display: inline-block; }
    .blog_right_sidebar .tag_cloud_widget ul li a {
      color: #222222;
      display: inline-block;
      border: 1px solid #eee;
      background: #fff;
      padding: 5px 13px;
      margin-bottom: 8px;
      transition: all 0.3s ease 0s;
      font-size: 12px; }
      .blog_right_sidebar .tag_cloud_widget ul li a:hover {
        background: linear-gradient(to right, #244cfd, #15e4fd);
        color: #ffffff; }
  .blog_right_sidebar .br {
    width: 100%;
    height: 1px;
    background: #eeeeee;
    margin: 30px 0px; }

.blog-pagination {
  padding-top: 25px;
  padding-bottom: 95px; }
  .blog-pagination .page-link {
    border-radius: 0; }
  .blog-pagination .page-item {
    border: none; }

.page-link {
  background: transparent;
  font-weight: 400; }

.blog-pagination .page-item.active .page-link {
  background: linear-gradient(to right, #244cfd, #15e4fd);
  border-color: transparent;
  color: #fff; }

.blog-pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #8a8a8a;
  border: none; }

.blog-pagination .page-item:last-child .page-link,
.blog-pagination .page-item:first-child .page-link {
  border-radius: 0; }

.blog-pagination .page-link:hover {
  color: #fff;
  text-decoration: none;
  background: linear-gradient(to right, #244cfd, #15e4fd);
  border-color: #eee; }

/* 17. Blog Details */
.single-post .social-links {
  padding-top: 10px; }
  .single-post .social-links li {
    display: inline-block;
    margin-bottom: 10px; }
    .single-post .social-links li a {
      color: #cccccc;
      padding: 7px;
      font-size: 14px;
      transition: all 0.2s linear; }
      .single-post .social-links li a:hover {
        color: #222222; }

.single-post .blog_details {
  padding-top: 26px; }
  .single-post .blog_details p {
    margin-bottom: 10px; }

.single-post .quotes {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 24px 35px 24px 30px;
  background-color: white;
  box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
  font-size: 14px;
  line-height: 24px;
  color: #777;
  font-style: italic; }

.single-post .arrow {
  position: absolute; }
  .single-post .arrow .lnr {
    font-size: 20px;
    font-weight: 600; }

.single-post .thumb .overlay-bg {
  background: rgba(0, 0, 0, 0.8); }

@media (max-width: 991px) {
  .single-post .sidebar-widgets {
    padding-bottom: 0px; } }

.comments-area {
  background: #fafaff;
  border: 1px solid #eee;
  padding: 50px 30px;
  margin-top: 50px; }
  @media (max-width: 414px) {
    .comments-area {
      padding: 50px 8px; } }
  .comments-area h4 {
    text-align: center;
    margin-bottom: 50px;
    color: #222222;
    font-size: 18px; }
  .comments-area h5 {
    font-size: 16px;
    margin-bottom: 0px; }
  .comments-area a {
    color: #222222; }
  .comments-area .comment-list {
    padding-bottom: 48px; }
    .comments-area .comment-list:last-child {
      padding-bottom: 0px; }
    .comments-area .comment-list.left-padding {
      padding-left: 25px; }
    @media (max-width: 413px) {
      .comments-area .comment-list .single-comment h5 {
        font-size: 12px; }
      .comments-area .comment-list .single-comment .date {
        font-size: 11px; }
      .comments-area .comment-list .single-comment .comment {
        font-size: 10px; } }
  .comments-area .thumb {
    margin-right: 20px; }
  .comments-area .date {
    font-size: 13px;
    color: #cccccc;
    margin-bottom: 13px; }
  .comments-area .comment {
    color: #777777;
    margin-bottom: 0px; }
  .comments-area .btn-reply {
    background-color: #fff;
    color: #222222;
    border: 1px solid #eee;
    padding: 5px 18px;
    font-size: 12px;
    display: block;
    font-weight: 600;
    transition: .3s; }
    .comments-area .btn-reply:hover {
      background: linear-gradient(to right, #244cfd, #15e4fd);
      color: #ffffff !important; }

.comment-form {
  background: #fafaff;
  text-align: center;
  border: 1px solid #eee;
  padding: 47px 30px 43px;
  margin-top: 50px;
  margin-bottom: 40px; }
  .comment-form h4 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 22px;
    color: #222222; }
  .comment-form .name {
    padding-left: 0px; }
    @media (max-width: 767px) {
      .comment-form .name {
        padding-right: 0px;
        margin-bottom: 1rem; } }
  .comment-form .email {
    padding-right: 0px; }
    @media (max-width: 991px) {
      .comment-form .email {
        padding-left: 0px; } }
  .comment-form .form-control {
    padding: 8px 20px;
    background: #fff;
    border: none;
    border-radius: 0px;
    width: 100%;
    font-size: 14px;
    color: #777777;
    border: 1px solid transparent; }
    .comment-form .form-control:focus {
      box-shadow: none;
      border: 1px solid #eee; }
  .comment-form textarea.form-control {
    height: 140px;
    resize: none; }
  .comment-form ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 13px;
    color: #777; }
  .comment-form ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 13px;
    color: #777; }
  .comment-form :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 13px;
    color: #777; }
  .comment-form :-moz-placeholder {
    /* Firefox 18- */
    font-size: 13px;
    color: #777; }

/* 18. Contact */
#mapBox {
  width: 100%;
  height: 420px;
  overflow: hidden; }

.info-text h3 {
  text-transform: inherit; }

.into-icon i {
  font-size: 20px;
  color: #244cfd;
  margin-right: 20px; }

.contact-form form .left,
.commentform-area form .left {
  width: 48%;
  float: left;
  overflow: hidden;
  margin-right: 4%; }
  @media (max-width: 575.98px) {
    .contact-form form .left,
    .commentform-area form .left {
      width: 100%;
      float: none; } }
  .contact-form form .left input,
  .commentform-area form .left input {
    width: 100%;
    border: 1px solid #f9f9f9;
    padding: 12px 20px;
    margin-bottom: 20px; }

.contact-form form .right,
.commentform-area form .right {
  width: 48%;
  overflow: hidden; }
  @media (max-width: 575.98px) {
    .contact-form form .right,
    .commentform-area form .right {
      width: 100%;
      float: none; } }
  .contact-form form .right textarea,
  .commentform-area form .right textarea {
    width: 100%;
    border: 1px solid #f9f9f9;
    padding: 12px 20px; }

.contact-form form button,
.commentform-area form button {
  margin-top: 20px;
  float: right;
  border: none; }
  @media (max-width: 575.98px) {
    .contact-form form button,
    .commentform-area form button {
      float: left; } }

/* 21. Elements */
.mb-20 {
  margin-bottom: 20px; }

.mb-30 {
  margin-bottom: 30px; }

.sample-text-area {
  padding: 100px 0px; }
  .sample-text-area .title_color {
    margin-bottom: 30px; }
  .sample-text-area p {
    line-height: 26px; }
    .sample-text-area p b {
      font-weight: bold;
      color: #244cfd; }
    .sample-text-area p i {
      color: #244cfd;
      font-style: italic; }
    .sample-text-area p sup {
      color: #244cfd;
      font-style: italic; }
    .sample-text-area p sub {
      color: #244cfd;
      font-style: italic; }
    .sample-text-area p del {
      color: #244cfd; }
    .sample-text-area p u {
      color: #244cfd; }

/*============== End Elements Area css ================*/
/*==============Elements Button Area css ================*/
.elements_button .title_color {
  margin-bottom: 30px;
  color: #222222; }

.title_color {
  color: #222222; }

.button-group-area {
  margin-top: 15px; }
  .button-group-area:nth-child(odd) {
    margin-top: 40px; }
  .button-group-area:first-child {
    margin-top: 0px; }
  .button-group-area .theme_btn {
    margin-right: 10px; }
  .button-group-area .white_btn {
    margin-right: 10px; }
  .button-group-area .link {
    text-decoration: underline;
    color: #222222;
    background: transparent; }
    .button-group-area .link:hover {
      color: #fff; }
  .button-group-area .disable {
    background: transparent;
    color: #007bff;
    cursor: not-allowed; }
    .button-group-area .disable:before {
      display: none; }

.primary {
  background: #52c5fd; }
  .primary:before {
    background: #2faae6; }

.success {
  background: #4cd3e3; }
  .success:before {
    background: #2ebccd; }

.info {
  background: #38a4ff; }
  .info:before {
    background: #298cdf; }

.warning {
  background: #f4e700; }
  .warning:before {
    background: #e1d608; }

.danger {
  background: #f54940; }
  .danger:before {
    background: #e13b33; }

.primary-border {
  background: transparent;
  border: 1px solid #52c5fd;
  color: #52c5fd; }
  .primary-border:before {
    background: #52c5fd; }

.success-border {
  background: transparent;
  border: 1px solid #4cd3e3;
  color: #4cd3e3; }
  .success-border:before {
    background: #4cd3e3; }

.info-border {
  background: transparent;
  border: 1px solid #38a4ff;
  color: #38a4ff; }
  .info-border:before {
    background: #38a4ff; }

.warning-border {
  background: #fff;
  border: 1px solid #f4e700;
  color: #f4e700; }
  .warning-border:before {
    background: #f4e700; }

.danger-border {
  background: transparent;
  border: 1px solid #f54940;
  color: #f54940; }
  .danger-border:before {
    background: #f54940; }

.link-border {
  background: transparent;
  border: 1px solid #244cfd;
  color: #244cfd; }
  .link-border:before {
    background: #244cfd; }

.radius {
  border-radius: 3px; }

.circle {
  border-radius: 20px; }

.arrow span {
  padding-left: 5px; }

.e-large {
  line-height: 50px;
  padding-top: 0px;
  padding-bottom: 0px; }

.large {
  line-height: 45px;
  padding-top: 0px;
  padding-bottom: 0px; }

.medium {
  line-height: 30px;
  padding-top: 0px;
  padding-bottom: 0px; }

.small {
  line-height: 25px;
  padding-top: 0px;
  padding-bottom: 0px; }

.general {
  line-height: 38px;
  padding-top: 0px;
  padding-bottom: 0px; }

/*==============End Elements Button Area css ================*/
/* =================================== */
/*  Elements Page Styles
/* =================================== */
/*---------- Start Elements Page -------------*/
.generic-banner {
  margin-top: 60px;
  text-align: center; }

.generic-banner .height {
  height: 600px; }

@media (max-width: 767.98px) {
  .generic-banner .height {
    height: 400px; } }

.generic-banner .generic-banner-content h2 {
  line-height: 1.2em;
  margin-bottom: 20px; }

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content h2 br {
    display: none; } }

.generic-banner .generic-banner-content p {
  text-align: center;
  font-size: 16px; }

@media (max-width: 991.98px) {
  .generic-banner .generic-banner-content p br {
    display: none; } }

.generic-content h1 {
  font-weight: 600; }

.about-generic-area {
  background: #fff; }

.about-generic-area p {
  margin-bottom: 20px; }

.white-bg {
  background: #fff; }

.section-top-border {
  padding: 50px 0;
  border-top: 1px dotted #eee; }

.switch-wrap {
  margin-bottom: 10px; }

.switch-wrap p {
  margin: 0; }

/*---------- End Elements Page -------------*/
.sample-text-area {
  padding: 100px 0 70px 0; }

.sample-text {
  margin-bottom: 0; }

.text-heading {
  margin-bottom: 30px;
  font-size: 24px; }

.typo-list {
  margin-bottom: 10px; }

@media (max-width: 767px) {
  .typo-sec {
    margin-bottom: 30px; } }

@media (max-width: 767px) {
  .element-wrap {
    margin-top: 30px; } }

b, sup, sub, u, del {
  color: #f8b600; }

h1 {
  font-size: 36px; }

h2 {
  font-size: 30px; }

h3 {
  font-size: 24px; }

h4 {
  font-size: 18px; }

h5 {
  font-size: 16px; }

h6 {
  font-size: 14px; }

.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
  color: #777777; }

.button-area .border-top-generic {
  padding: 70px 15px;
  border-top: 1px dotted #eee; }

.button-group-area .genric-btn {
  margin-right: 10px;
  margin-top: 10px; }

.button-group-area .genric-btn:last-child {
  margin-right: 0; }

.circle {
  border-radius: 20px; }

.genric-btn {
  display: inline-block;
  outline: none;
  line-height: 40px;
  padding: 0 30px;
  font-size: .8em;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.genric-btn:focus {
  outline: none; }

.genric-btn.e-large {
  padding: 0 40px;
  line-height: 50px; }

.genric-btn.large {
  line-height: 45px; }

.genric-btn.medium {
  line-height: 30px; }

.genric-btn.small {
  line-height: 25px; }

.genric-btn.radius {
  border-radius: 3px; }

.genric-btn.circle {
  border-radius: 20px; }

.genric-btn.arrow {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.genric-btn.arrow span {
  margin-left: 10px; }

.genric-btn.default {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent; }

.genric-btn.default:hover {
  border: 1px solid #f9f9ff;
  background: #fff; }

.genric-btn.default-border {
  border: 1px solid #f9f9ff;
  background: #fff; }

.genric-btn.default-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent; }

.genric-btn.primary {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent; }

.genric-btn.primary:hover {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff; }

.genric-btn.primary-border {
  color: #f8b600;
  border: 1px solid #f8b600;
  background: #fff; }

.genric-btn.primary-border:hover {
  color: #fff;
  background: #f8b600;
  border: 1px solid transparent; }

.genric-btn.success {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent; }

.genric-btn.success:hover {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff; }

.genric-btn.success-border {
  color: #4cd3e3;
  border: 1px solid #4cd3e3;
  background: #fff; }

.genric-btn.success-border:hover {
  color: #fff;
  background: #4cd3e3;
  border: 1px solid transparent; }

.genric-btn.info {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent; }

.genric-btn.info:hover {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff; }

.genric-btn.info-border {
  color: #38a4ff;
  border: 1px solid #38a4ff;
  background: #fff; }

.genric-btn.info-border:hover {
  color: #fff;
  background: #38a4ff;
  border: 1px solid transparent; }

.genric-btn.warning {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent; }

.genric-btn.warning:hover {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff; }

.genric-btn.warning-border {
  color: #f4e700;
  border: 1px solid #f4e700;
  background: #fff; }

.genric-btn.warning-border:hover {
  color: #fff;
  background: #f4e700;
  border: 1px solid transparent; }

.genric-btn.danger {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent; }

.genric-btn.danger:hover {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff; }

.genric-btn.danger-border {
  color: #f44a40;
  border: 1px solid #f44a40;
  background: #fff; }

.genric-btn.danger-border:hover {
  color: #fff;
  background: #f44a40;
  border: 1px solid transparent; }

.genric-btn.link {
  color: #222222;
  background: #f9f9ff;
  text-decoration: underline;
  border: 1px solid transparent; }

.genric-btn.link:hover {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff; }

.genric-btn.link-border {
  color: #222222;
  border: 1px solid #f9f9ff;
  background: #fff;
  text-decoration: underline; }

.genric-btn.link-border:hover {
  color: #222222;
  background: #f9f9ff;
  border: 1px solid transparent; }

.genric-btn.disable {
  color: #222222, 0.3;
  background: #f9f9ff;
  border: 1px solid transparent;
  cursor: not-allowed; }

.generic-blockquote {
  padding: 30px 50px 30px 30px;
  background: #fff;
  border-left: 2px solid #244cfd; }

@media (max-width: 991px) {
  .progress-table-wrap {
    overflow-x: scroll; } }

.progress-table {
  background: #fff;
  padding: 15px 0px 30px 0px;
  min-width: 800px; }

.progress-table .serial {
  width: 11.83%;
  padding-left: 30px; }

.progress-table .country {
  width: 28.07%; }

.progress-table .visit {
  width: 19.74%; }

.progress-table .percentage {
  width: 40.36%;
  padding-right: 50px; }

.progress-table .table-head {
  display: flex; }

.progress-table .table-head .serial, .progress-table .table-head .country, .progress-table .table-head .visit, .progress-table .table-head .percentage {
  color: #222222;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: 500; }

.progress-table .table-row {
  padding: 15px 0;
  border-top: 1px solid #edf3fd;
  display: flex; }

.progress-table .table-row .serial, .progress-table .table-row .country, .progress-table .table-row .visit, .progress-table .table-row .percentage {
  display: flex;
  align-items: center; }

.progress-table .table-row .country img {
  margin-right: 15px; }

.progress-table .table-row .percentage .progress {
  width: 80%;
  border-radius: 0px;
  background: transparent; }

.progress-table .table-row .percentage .progress .progress-bar {
  height: 5px;
  line-height: 5px; }

.progress-table .table-row .percentage .progress .progress-bar.color-1 {
  background-color: #6382e6; }

.progress-table .table-row .percentage .progress .progress-bar.color-2 {
  background-color: #e66686; }

.progress-table .table-row .percentage .progress .progress-bar.color-3 {
  background-color: #f09359; }

.progress-table .table-row .percentage .progress .progress-bar.color-4 {
  background-color: #73fbaf; }

.progress-table .table-row .percentage .progress .progress-bar.color-5 {
  background-color: #73fbaf; }

.progress-table .table-row .percentage .progress .progress-bar.color-6 {
  background-color: #6382e6; }

.progress-table .table-row .percentage .progress .progress-bar.color-7 {
  background-color: #a367e7; }

.progress-table .table-row .percentage .progress .progress-bar.color-8 {
  background-color: #e66686; }

.single-gallery-image {
  margin-top: 30px;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  height: 200px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s; }

.single-gallery-image:hover {
  opacity: .8; }

.list-style {
  width: 14px;
  height: 14px; }

.unordered-list li {
  position: relative;
  padding-left: 30px;
  line-height: 1.82em !important; }

.unordered-list li:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border: 3px solid #f8b600;
  background: #fff;
  top: 4px;
  left: 0;
  border-radius: 50%; }

.ordered-list {
  margin-left: 30px; }

.ordered-list li {
  list-style-type: decimal-leading-zero;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important; }

.ordered-list li span {
  font-weight: 300;
  color: #777777; }

.ordered-list-alpha li {
  margin-left: 30px;
  list-style-type: lower-alpha;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important; }

.ordered-list-alpha li span {
  font-weight: 300;
  color: #777777; }

.ordered-list-roman li {
  margin-left: 30px;
  list-style-type: lower-roman;
  color: #f8b600;
  font-weight: 500;
  line-height: 1.82em !important; }

.ordered-list-roman li span {
  font-weight: 300;
  color: #777777; }

.single-input {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px; }

.single-input:focus {
  outline: none; }

.input-group-icon {
  position: relative; }

.input-group-icon .icon {
  position: absolute;
  left: 20px;
  top: 0;
  line-height: 40px;
  z-index: 3; }

.input-group-icon .icon i {
  color: #797979; }

.input-group-icon .single-input {
  padding-left: 45px; }

.single-textarea {
  display: block;
  width: 100%;
  line-height: 40px;
  border: none;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px;
  height: 100px;
  resize: none; }

.single-textarea:focus {
  outline: none; }

.single-input-primary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px; }

.single-input-primary:focus {
  outline: none;
  border: 1px solid #f8b600; }

.single-input-accent {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px; }

.single-input-accent:focus {
  outline: none;
  border: 1px solid #eb6b55; }

.single-input-secondary {
  display: block;
  width: 100%;
  line-height: 40px;
  border: 1px solid transparent;
  outline: none;
  background: #f9f9ff;
  padding: 0 20px; }

.single-input-secondary:focus {
  outline: none;
  border: 1px solid #f09359; }

.default-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer; }

.default-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer; }

.default-switch input + label {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #f8b600;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer; }

.default-switch input:checked + label {
  left: 19px; }

.single-element-widget {
  margin-bottom: 30px; }

.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer; }

.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s; }

.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer; }

.primary-switch input:checked + label:after {
  left: 19px; }

.primary-switch input:checked + label:before {
  background: #f8b600; }

.confirm-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer; }

.confirm-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.confirm-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%; }

.confirm-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer; }

.confirm-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer; }

.confirm-switch input:checked + label:after {
  left: 19px; }

.confirm-switch input:checked + label:before {
  background: #4cd3e3; }

.primary-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer; }

.primary-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.primary-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1; }

.single-defination h4 {
  color: #222222; }

.primary-checkbox input:checked + label {
  background: url(../images/elements/primary-check.png) no-repeat center center/cover;
  border: none; }

.confirm-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer; }

.confirm-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.confirm-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1; }

.confirm-checkbox input:checked + label {
  background: url(../images/elements/success-check.png) no-repeat center center/cover;
  border: none; }

.disabled-checkbox {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #fff;
  position: relative;
  cursor: pointer; }

.disabled-checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.disabled-checkbox input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f1f1f1; }

.disabled-checkbox input:disabled {
  cursor: not-allowed;
  z-index: 3; }

.disabled-checkbox input:checked + label {
  background: url(../images/elements/disabled-check.png) no-repeat center center/cover;
  border: none; }

.primary-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer; }

.primary-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.primary-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1; }

.primary-radio input:checked + label {
  background: url(../images/elements/primary-radio.png) no-repeat center center/cover;
  border: none; }

.confirm-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer; }

.confirm-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.confirm-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1; }

.confirm-radio input:checked + label {
  background: url(../images/elements/success-radio.png) no-repeat center center/cover;
  border: none; }

.disabled-radio {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  cursor: pointer; }

.disabled-radio input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0; }

.disabled-radio input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #f1f1f1; }

.disabled-radio input:disabled {
  cursor: not-allowed;
  z-index: 3; }

.unordered-list {
  list-style: none;
  padding: 0px;
  margin: 0px; }

.ordered-list {
  list-style: none;
  padding: 0px; }

.disabled-radio input:checked + label {
  background: url(../images/elements/disabled-radio.png) no-repeat center center/cover;
  border: none; }

.default-select {
  height: 40px; }

.default-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #fff;
  padding-left: 20px;
  padding-right: 40px; }

.default-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px; }

.default-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 20px; }

.default-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent; }

.default-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent; }

.default-select .current {
  margin-right: 50px;
  font-weight: 300; }

.default-select .nice-select::after {
  right: 20px; }

@media (max-width: 991px) {
  .left-align-p p {
    margin-top: 20px; } }

.form-select {
  height: 40px;
  width: 100%; }

.form-select .nice-select {
  border: none;
  border-radius: 0px;
  height: 40px;
  background: #f9f9ff !important;
  padding-left: 45px;
  padding-right: 40px;
  width: 100%; }

.form-select .nice-select .list {
  margin-top: 0;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  width: 100%;
  padding: 10px 0 10px 0px; }

.mt-10 {
  margin-top: 10px; }

.form-select .nice-select .list .option {
  font-weight: 300;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 28px;
  min-height: 28px;
  font-size: 12px;
  padding-left: 45px; }

.form-select .nice-select .list .option.selected {
  color: #f8b600;
  background: transparent; }

.form-select .nice-select .list .option:hover {
  color: #f8b600;
  background: transparent; }

.form-select .current {
  margin-right: 50px;
  font-weight: 300; }

.form-select .nice-select::after {
  right: 20px; }

.page_link a {
  color: #ffffff; }

/* 15. Button */
.template-btn {
  color: #ffffff;
  background: linear-gradient(to right, #244cfd, #15e4fd);
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  padding: 12px 30px !important;
  transition: all .2s ease-in-out; }
  .template-btn:hover {
    color: #ffffff;
    opacity: 0.8; }

.news-btn {
  color: #222222;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  transition: all .5s ease-in-out; }
  .news-btn:hover {
    color: #244cfd; }
    .news-btn:hover i {
      right: -20px; }
  .news-btn i {
    position: absolute;
    right: -17px;
    top: 1px;
    transition: all .2s ease-in-out; }

/* 16. Form */
.appointment-form input,
.appointment-form textarea {
  width: 100%;
  padding: 12px 20px;
  border: none; }

.appointment-form .template-btn {
  width: 100%; }

/* 17. Other */
.section-padding {
  padding: 50px 0; }

.section-padding2 {
  padding-top: 130px; }

.section-padding3 {
  padding-bottom: 120px; }

.section-padding4 {
  padding: 130px 0 200px; }

.section-top {
  margin-bottom: 80px; }
  .section-top .style-change {
    color: #244cfd;
    font-style: italic; }

.section-top2 {
  margin-bottom: 80px; }
  .section-top2 span {
    color: #244cfd; }

.item-padding {
  padding: 40px 20px; }

.single-feature2 {
  border-radius: 5px;
  transition: 0.5s; }
  .single-feature2 img {
    margin-bottom: 40px; }

.universal0 {
  margin-top: 150px; }
  .universal0 .container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: auto; }
    .universal0 .container .item {
      width: 340px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 16px 3px #0000001f; }
      @media only screen and (max-width: 1192px) {
        .universal0 .container .item {
          width: 396px;
          margin: 24px; } }
      @media only screen and (max-width: 1024px) {
        .universal0 .container .item {
          width: 100%; } }
      @media only screen and (max-width: 411px) {
        .universal0 .container .item {
          width: 100%;
          margin: 10px 0; } }
      .universal0 .container .item .category__item-done {
        display: flex;
        justify-content: space-between;
        flex-direction: column; }
        .universal0 .container .item .category__item-done .category__image-done img {
          width: 100%; }
          @media only screen and (max-width: 1024px) {
            .universal0 .container .item .category__item-done .category__image-done img {
              width: 100%; } }
          @media only screen and (max-width: 411px) {
            .universal0 .container .item .category__item-done .category__image-done img {
              width: 100%; } }
        .universal0 .container .item .category__item-done .information-wrapper {
          padding: 20px; }
          .universal0 .container .item .category__item-done .information-wrapper .category__title {
            max-height: 75px; }
            .universal0 .container .item .category__item-done .information-wrapper .category__title span {
              font-size: 19px; }
          .universal0 .container .item .category__item-done .information-wrapper .price-wrapper {
            font-size: 25px;
            color: #0076ff;
            text-align: left; }

.single-feature3 {
  transition: 0.5s;
  position: relative; }
  .single-feature3 img {
    max-width: 285px;
    max-height: inherit; }

.shop-box .container .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-self: stretch; }
  .shop-box .container .row .item {
    width: 25%;
    height: 285px;
    background: #f9f9fd;
    transition: 0.3s; }
    .shop-box .container .row .item h3 {
      text-align: center; }
    @media only screen and (max-width: 1024px) {
      .shop-box .container .row .item {
        width: 100%; } }
    @media only screen and (max-width: 411px) {
      .shop-box .container .row .item {
        width: 100%; } }
    .shop-box .container .row .item:hover {
      background: #0086eabf;
      transition: 0.3s; }

.catalog-box .container .row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-self: stretch;
  background: #f9f9fd; }
  .catalog-box .container .row .item {
    width: 23%;
    height: 285px;
    margin: 10px;
    transition: .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center; }
    @media only screen and (max-width: 1024px) {
      .catalog-box .container .row .item {
        width: 100%;
        height: 414px; } }
    @media only screen and (max-width: 411px) {
      .catalog-box .container .row .item {
        width: 100%; } }
    .catalog-box .container .row .item:hover {
      background: linear-gradient(to top right, rgba(21, 228, 253, 0.9), rgba(36, 76, 253, 0.9));
      transition: all 0.3s;
      transform: scale(1.12); }
      .catalog-box .container .row .item:hover h3 {
        color: #fff; }

.towar .container4 {
  margin-top: 100px; }
  .towar .container4 .text {
    display: flex;
    justify-content: center;
    margin-top: 200px;
    margin-bottom: 200px; }
  .towar .container4 .row4 {
    display: flex;
    justify-content: space-around; }
    .towar .container4 .row4 .towar-item {
      width: 250px;
      height: 250px;
      background: #f9f9fd;
      transition: .3s; }
      .towar .container4 .row4 .towar-item .item-padding {
        padding: 60px 20px; }
      .towar .container4 .row4 .towar-item:hover {
        background: #0086eabf;
        transition: .3s; }
        .towar .container4 .row4 .towar-item:hover h3 {
          color: #fff; }

.left-category {
  position: fixed;
  background: #0086ea;
  overflow-x: auto;
  overflow-y: scroll;
  left: 0;
  width: 16%;
  padding: 30px;
  top: 0;
  z-index: 99999;
  bottom: 0;
  box-shadow: 7px 0 20px 0 #59687163; }
  @media only screen and (max-width: 1600px) {
    .left-category {
      display: none; } }
  .left-category ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: all 0.1s;
    border: 3px solid #0086ea00; }
    .left-category ul li:hover {
      border: 3px solid #fff;
      cursor: pointer;
      transition: all 0.2s; }
    .left-category ul li a {
      color: #fff;
      line-height: 30px;
      text-align: center;
      margin: 5px;
      width: 100%; }
  .left-category ul .active {
    border: 3px solid #fff; }

.card-gallery__bottom .slick-slide {
  padding: 0 8.5px; }

.card-gallery__bottom .slick-slide a,
.card-gallery__bottom .slick-slide img {
  display: block !important; }

.card-gallery__bottom .slick-slide a {
  cursor: pointer; }

.card-gallery__bottom .slick-slide img {
  max-width: 100%;
  border-bottom: 6px solid transparent;
  outline-offset: -6; }

.card-gallery__bottom .slick-slide img.active {
  border-bottom: #c6d250 solid 6px;
  outline-offset: -6px; }

.slick-slider {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list,
.slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0s;
  transition: all 0s; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: 0; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-track {
  position: relative;
  top: 0;
  left: 0; }

.slick-track:after,
.slick-track:before {
  content: '';
  display: table; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-arrow {
  padding: 0; }

.slick-arrow:focus {
  outline: 0; }

.slick__arrow.slick-hidden {
  display: none; }

.slick-slide {
  float: left;
  min-height: 1px; }

[dir=rtl] .slick-slide {
  float: right; }

.slick-slide:focus {
  outline: 0;
  cursor: move; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-slide > img {
  display: block; }

.slick-slide.dragging > img {
  pointer-events: none; }

.slick-dots button:focus {
  outline: 0; }

#msProduct {
  display: flex; }

.mrgntop {
  margin-top: 140px; }

.mrgntopsmall {
  margin-top: 30px; }

.card {
  margin: 10px !important;
  width: 215px !important; }
  @media only screen and (max-width: 520px) {
    .card {
      width: 100% !important; } }

.btn {
  white-space: pre-wrap !important; }

.card-body {
  justify-content: center !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 30px !important; }

.form-desc__form {
  color: #fff; }
  .form-desc__form button {
    border: none !important; }
  .form-desc__form .form-item label {
    color: #fff;
    font-size: 16px; }

.contact-form .container .col-lg-9 .form-desc__form {
  color: black !important; }
  .contact-form .container .col-lg-9 .form-desc__form #text {
    color: black !important; }
