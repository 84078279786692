.towar {
 .container4 {
 	margin-top: 100px;

	 .text{
	 	display: flex;
	 	justify-content: center;
	 	margin-top: 200px;
	 	margin-bottom: 200px;
	 }

	 .row4 {
		 display: flex;
		 justify-content: space-around;

        .towar-item{
         width: 250px;
         height: 250px;
         background: #f9f9fd;
         transition: .3s;


        .item-padding{
        	padding: 60px 20px;
        }
        &:hover{
		  background: #0086eabf;
          transition: .3s;
          h3{
          	color: #fff;
          }
        }
	}
}
}
}
