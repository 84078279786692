/* 3. Main Menu */
.nav-menu, .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .nav-menu ul {
    position: absolute;
    display: none;
    top: 100%;
    left: 0;
    z-index: 99;
  }

  .nav-menu li {
    position: relative;
    white-space: nowrap;
  }

  .nav-menu > li {
    float: left;
  }

  .nav-menu li:hover > ul,
  .nav-menu li.sfHover > ul {
    display: block;
  }

  .nav-menu ul ul {
    top: 0;
    left: 100%;
  }

  .nav-menu ul li {
    min-width: 180px;
    text-align:left;
  }


  .menu-has-children ul li a{
    padding:4px 10px!important;
    text-transform:uppercase!important;
  }



  // Nav Menu Arrows
  .sf-arrows .sf-with-ul {
    padding-right: 30px;
  }

  .sf-arrows .sf-with-ul:after {
    content: "\f107";
    position: absolute;
    right: 15px;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
  }

  .sf-arrows ul .sf-with-ul:after {
    content: "\f105";
  }

  // Nav Meu Container
  #nav-menu-container {
    margin: 0;
  }



  @media (max-width: 960px) {
    #nav-menu-container {
      display: none;
    }
  }

  // Nav Meu Styling
  .nav-menu a {
    padding:7px 10px;
    text-decoration: none;
    display: inline-block;
    color: $heading-text;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    outline: none;
  }

  .nav-menu li:hover > a, .nav-menu > .menu-active > a {
    // color: $primary-color;
  }

  .nav-menu > li {
    margin-left: 15px;
  }

  .nav-menu ul {
    margin: 14px 0 0 0;
    padding: 10px;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    background: #fff;
  }





  .nav-menu ul li {
    transition: 0.3s;
  }

  .nav-menu ul li a {
    padding: 10px;
    color: #333;
    transition: 0.3s;
    display: block;
    font-size: 12px;
    text-transform: none;
  }

  .nav-menu ul li:hover > a {
    color: $primary-color;
  }

  .nav-menu ul ul {
    margin: 0;
  }

  // Mobile Nav Toggle
  #mobile-nav-toggle {
    position: fixed;
    right: 15px;
    z-index: 999;
    top: 20px;
    border: 0;
    background: none;
    font-size: 24px;
    display: none;
    transition: all 0.4s;
    outline: none;
    cursor: pointer;
  }

  #mobile-nav-toggle i {
    color: $primary-color;
    font-weight: 900;
  }

  @media (max-width: 960px) {
    #mobile-nav-toggle {
      display: inline;
    }
    #nav-menu-container{
      display:none;
    }
  }

  // Mobile Nav Styling
  #mobile-nav {
    position: fixed;
    top: 0;
    padding-top: 18px;
    bottom: 0;
    z-index: 998;
    background: rgba(0, 0, 0, 0.8);
    left: -260px;
    width: 260px;
    overflow-y: auto;
    transition: 0.4s;
  }

  #mobile-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  #mobile-nav ul li {
    position: relative;
  }

  #mobile-nav ul li a {
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    overflow: hidden;
    padding: 10px 22px 10px 15px;
    position: relative;
    text-decoration: none;
    width: 100%;
    display: block;
    outline: none;
    font-weight: 700;
  }

  #mobile-nav ul li a:hover {
    color: #fff;
  }

  #mobile-nav ul li li {
    padding-left: 30px;
  }

  #mobile-nav ul .menu-has-children i {
    position: absolute;
    right: 0;
    z-index: 99;
    padding: 15px;
    cursor: pointer;
    color: #fff;
  }

  #mobile-nav ul .menu-has-children i.fa-chevron-up {
    color: $primary-color;
  }

  #mobile-nav ul .menu-has-children li a {
    text-transform: capitalize;
  }

  #mobile-nav ul .menu-item-active {
    color: $primary-color;
  }

  #mobile-body-overly {
    width: 100%;
    height: 100%;
    z-index: 997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    display: none;
  }

  // Mobile Nav body classes
  body.mobile-nav-active {
    overflow: hidden;
  }

  body.mobile-nav-active #mobile-nav {
    left: 0;
  }

  body.mobile-nav-active #mobile-nav-toggle {
    color: #fff;
  }
