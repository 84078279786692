@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500,700');


@import 'sass/setting/_variable.scss';
@import 'sass/setting/_mixin.scss';
@import 'sass/base/_base.scss';
@import 'sass/layout/_preloader.scss';
@import 'sass/layout/_nav.scss';
@import 'sass/layout/_header.scss';
@import 'sass/layout/_banner.scss';
@import 'sass/layout/_feature.scss';
@import 'sass/layout/_welcome.scss';
@import 'sass/layout/_department.scss';
@import 'sass/layout/_patient.scss';
@import 'sass/layout/_specialist.scss';
@import 'sass/layout/_hotline.scss';
@import 'sass/layout/_news.scss';
@import 'sass/layout/_footer.scss';
@import 'sass/layout/_departments-page.scss';
@import 'sass/layout/_about.scss';
@import 'sass/layout/_blog-home.scss';
@import 'sass/layout/_blog-details.scss';
@import 'sass/layout/_contact.scss';
@import 'sass/layout/_elements.scss';
@import 'sass/module/_btn.scss';
@import 'sass/module/_form.scss';
@import 'sass/module/_other.scss';
@import 'sass/layout/shop-box.scss';
@import 'sass/layout/catalog.scss';
@import 'sass/layout/towar.scss';
@import 'sass/layout/custom.scss';
