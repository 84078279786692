/* 1. Author CSS */

body {
  color: $body-text;
  font-family: $body-font;
  font-size: 15px;
  font-weight: 400;
}

h1 {
  color: $heading-text;
  font-family: $heading-font;
  font-size: 45px!important;
  font-weight: 700;
}

h2 {
  color: $heading-text;
  font-family: $heading-font;
  font-size: 36px!important;
  font-weight: 700;
}

h3 {
  color: $heading-text;
  font-family: $heading-font;
  font-size: 18px!important;
  font-weight: 700;
  text-transform: uppercase;
}

h4 {
  color: $heading-text;
  font-family: $heading-font;
  font-size: 16px!important;
  font-weight: 400;
}

h5 {
  color: $body-text;
  font-family: $body-font;
  text-transform: capitalize;
  font-size: 14px!important;
  font-weight: 400;
}

h6 {
  color: $body-text;
  font-size: 12px!important;
  font-family: $body-font;
  font-weight: 500;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

input:focus,
textarea:focus {
  outline: none;
}
.custom-a{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  h3{
    word-wrap: break-word;
  }
}
.category-ul {
  justify-content: center;
display: flex;
flex-direction: column;
  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;

    li {
      display: flex;
      width: 28%;
      margin: 27px;
      @media only screen and (max-width: 1024px){
        width: 95%;
      }

      @media only screen and (max-width: 411px){

      }

      a {
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        justify-content: center;
        padding: 20px;
        height: 280px;
        outline: 17px solid;
        transition: all .3s ease 0s;
        outline-color: #f0f8ff;
        font-size: 22px;
        color: #000000;

        &:hover {
          transition: all .3s ease 0s;
          background: linear-gradient(to top right,rgba(21,228,253,.9),rgba(36,76,253,.9));
          color: #fff;
          outline: 0px solid;
          outline-color: #fff;
          transform: scale(1.12);

        }
      }
    }
  }
}
